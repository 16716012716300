import axios from "axios";
import { getToken, setToken, deleteToken } from './auth-helper'


export const axiosInstance = axios.create({	
	//timeout: 1000,
    baseURL: 'https://www.beautydermus.com.uy/FrontEndApi',    
    //baseURL: 'https://localhost:44310/',    
    headers: {
        "m-app-code":"bd"

    }
});



axiosInstance.interceptors.request.use(
    async config => {



        var token = await getToken();


        if (token !== null) {
            //config.headers.Authorization = `Bearer ${token}`;
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        else {
            config.headers["Authorization"] = `Bearer 1`;
        }




        return config;
    });

//axiosInstance.interceptors.response.use(
//    response => response,
//    error => {
//        if (error.response.status === 401 || error.response.status === 403) {
//            window.location = "/error-403";
//        } else {
//            return Promise.reject(error);
//        }
//    }
//);