/**
 * Fixed header component
 */
/* eslint-disable */
import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';

// components
import HeaderMenu from "./HeaderMenu";
import SidebarMenu from '../sidebar';
import AppConfig from '../../../constants/AppConfig';


function FixedHeader(props) {
   const navLinks = props.navLinks;

   return (
      <div className="iron-fixed-header mt-10">
         <div className="container blur rounded top-0 shadow border" >
            <Grid container spacing={0} >
               <Grid item xs={5} sm={5} md={2} lg={2} xl={2} className="d-flex justify-content-start align-items-center" >
                  <div className="iron-app-logo py-sm-10 py-20">
                     {/* <img src={AppConfig.AppLogo} alt={AppConfig.AppLogoAlt} width={AppConfig.AppLogoWidth} /> */}
                     <Link to="/">
                        <h1 className="beauty-logo">Beautydermus</h1>
                     </Link>
                  </div>
               </Grid>
               <Grid item xs={6} sm={6} md={8} lg={8} xl={8} >
                  <div className="text-center">
                     <HeaderMenu  navLinks={navLinks} showCart={true} />

                  </div>
               </Grid>
               <Grid item xs={1} sm={1} md={2} lg={2} xl={2} className="d-flex justify-content-end align-items-center" >
                  <div className="iron-header-widgets d-flex justify-content-end align-items-center ">
                     <SidebarMenu navLinks={navLinks}/>
                  </div>
               </Grid>

               
            </Grid>
         </div>
      </div>
   );
}

export default FixedHeader;