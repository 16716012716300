/**
 * Main App
 */
import React, { Fragment } from 'react';
import { axiosInstance } from './util/axiosInstance';

import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from "react-redux";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

// css
import './lib/embryoCss.js';

// App locale
import AppLocale from './lang';

//layout 
import HeaderOne from "./components/layouts/headers/HeaderOne";
import HeaderTwo from "./components/layouts/headers/HeaderTwo";
import HeaderThree from './components/layouts/headers/HeaderThree.js';


import FooterOne from "./components/layouts/footers/FooterOne";

//review component
import ProductReview from './components/global/review-popup/ProductReview';

import AppConfig from './constants/AppConfig';


//Add Loaders
import {
   AsyncShopByCategoriesComponent,
   AsyncHomePageOneComponent,
   AsyncHomePageTwoComponent,
   AsyncHomePageThreeComponent,
   AsyncAboutUSComponent,
   AsyncCartComponent,
   AsyncCheckOutComponent,
   AsyncBlogDetailComponent,
   AsyncAccessoriesComponent,
   AsyncContactUsComponent,
   AsyncFAQComponent,
   AsyncPaymentOptionsComponent,
   AsyncPrivacyPolicyComponent,
   AsyncTermAndConditionComponent,
   AsyncProductDetailComponent,
   AsyncInvoiceComponent,
   AsyncShopComponent,
   AsyncSignInPageComponent,
   AsyncSignUpComponent,
   AsyncForgetPasswordComponent,   
   AsyncProfileComponent,
   AsyncPageNotFoundComponent,
   AsyncBankPromotionsComponent,
   AsyncPasswordRecoveryComponent,
   AsyncOrderStateComponent,
   AsyncPuntosDeRetiroComponent,
   AsyncShopByCombosComponent
} from './util/AsyncRoutes';

// actions
import { hideAlert } from "./actions/action";

// footer data
import footerData from './assets/data/footerData';
import ThemeOptions from './components/ThemeOptions/ThemeOptions';
import AdminLayout from './components/AdminLayout';

import { UserProvider  } from './userContext';
import { getToken, setToken, deleteToken } from './util/auth-helper'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
   gtmId: 'GTM-5C77JB9'  

}

TagManager.initialize(tagManagerArgs)

TagManager.dataLayer({
   dataLayer: {
      event: 'pageview',
      path: '/'
   }
})


class App extends React.Component {

   constructor(props) {
      super(props);
      this.state = {

          categories: [
            { description: 'ACF', url:'/acf'},
            { description: 'Dadatina', url:'/Dadatina'},
            { description: 'Midermus', url:'/midermus'},
            { description: 'Vitamidermus', url:'/Vitamidermus'},
            
         ],
          user: {
             isAuth: false,
          }    
      };
      this.logout = this.logout.bind(this);
      this.login = this.login.bind(this);
  }

   logout() {
    
      
      this.setState({
         user: {
            ...this.state.user,
            isAuth: false,
            token: '',
         }
      });

      deleteToken();
      this.props.history.push("/");
   }



   login(data) {

      setToken(data.token);

      this.setState({
         user: {
            ...this.state.user,
            isAuth: true,
            token: data.token,
         }
      });
   }

   

	async componentDidMount(){		     
      // const { darkMode,rtlLayout } = this.props;
		// this.rtlLayoutOption(rtlLayout);
		// this.darkModeHanlder(darkMode);

      var token = await getToken();

      await axiosInstance.get('/api/User/GetUserProfile'
      , { 
         headers: { "Authorization": `Bearer ${token}` } 
      }
      )
      .then(response => {

         var data = response.data;

         if(data.id !== 0){
            this.setState({
               user: {
                   ...this.state.user,
                   isAuth: true,
               }
           });
         }
         else{
            this.setState({
               user: {
                   ...this.state.user,
                   isAuth: false,
               }
           });
         }
          
      })
      .catch(() => {
          this.setState({
              user: {
                  ...this.state.user,
                  isAuth: false,
              }
          });
      });


      this.getCategories();

	}

	rtlLayoutOption(rtlLayout) {
      if (rtlLayout === true) {
         document.body.classList.add("rtl-layout");
      }
      else {
         document.body.classList.remove("rtl-layout");
      }
	}
	
	darkModeHanlder(darkMode) {
		if (darkMode === true) {
			document.body.classList.add("dark-mode");
		}
		else {
			document.body.classList.remove("dark-mode");
		}
	}
 
   componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
         window.scrollTo(0, 0);
      }
   }

   getUrl(pathname) {
      let pathArray = pathname.split('/');
      return `/${pathArray[1]}` === '/admin-panel' ? true : false;
	}

   renderHeader(){
      const header = AppConfig.Header;


      switch(header){
         case 1:
            return <HeaderOne />
            case 2:
            return <HeaderTwo />
            case 3:
            return <HeaderThree />
            default:
               return <HeaderOne />
      }
   }


   async getCategories() {

      await axiosInstance.get(`/api/Home/GetCategoriesForAppRoutes`)
          .then(response => {
              this.setState({ categories: response.data })
          })       
  }



  
   render() {
      const { location } = this.props;
		const { selectedLocale, alertType,theme } = this.props;
		const currentAppLocale = AppLocale[selectedLocale.locale];

      const userValue = {
         user: this.state.user,
         logoutUser: this.logout,
         loginUser: this.login,
     }
  


      return (
         <MuiThemeProvider theme={theme}>
            <IntlProvider
               locale={currentAppLocale.locale}
               messages={currentAppLocale.messages}
            >
               <UserProvider value={userValue}>
                  <Fragment>
                  
                  {this.getUrl(location.pathname) ?

                     <Route path="/admin-panel" component={AdminLayout} />
                     :
                     <div className="app-container">
                       
                     {this.renderHeader()}
                   
                   
                        
                        <ProductReview />
                        <Switch>

                        {
                           this.state.categories.map((category, index) => {
                              return (<Route exact key={index} path={category.url} component={AsyncShopByCategoriesComponent} />)
                           })
                        }
                        {/*
                              this.state.categories.map((category, index) => {
                                 return (<Route exact key={index} path={category.url + "/:id"} component={AsyncProductDetail2Component} />)
                              })                                    
                           */}

                           <Route path="/combos" component={AsyncShopByCombosComponent} />


                           <Route exact path="/" component={AsyncHomePageOneComponent} />
                           <Route path="/home-two" component={AsyncHomePageTwoComponent} />
                           <Route path="/home-three" component={AsyncHomePageThreeComponent} />
                           <Route path="/producto/:id" component={AsyncProductDetailComponent} />
                           <Route path="/combo/:id" component={AsyncProductDetailComponent} />
                           <Route path="/cart" component={AsyncCartComponent} />
                           <Route path="/check-out" component={AsyncCheckOutComponent} />
                           <Route path="/payment" component={AsyncPaymentOptionsComponent} />
                           <Route path="/final-receipt" component={AsyncInvoiceComponent} />
                           <Route path="/accessories" component={AsyncAccessoriesComponent} />
                           <Route path="/shop" component={AsyncShopComponent} />
                           <Route path="/about-us" component={AsyncAboutUSComponent} />
                           <Route path="/term-and-condition" component={AsyncTermAndConditionComponent} />
                           <Route path="/privacy-policy" component={AsyncPrivacyPolicyComponent} />
                           <Route path="/faq" component={AsyncFAQComponent} />
                           <Route path="/blogs/detail/:id" component={AsyncBlogDetailComponent} />
                           <Route path="/sign-in" component={AsyncSignInPageComponent} />
                           <Route path="/sign-up" component={AsyncSignUpComponent} />
                           <Route path="/forget-password" component={AsyncForgetPasswordComponent} />
                           <Route path="/contact-us" component={AsyncContactUsComponent} />
                           <Route path="/medios-de-pagos" component={AsyncBankPromotionsComponent} />
                           <Route path="/account" component={AsyncProfileComponent} />
                           <Route path="/recuperar-usuario/:id" component={AsyncPasswordRecoveryComponent} />
                           <Route path="/estado-pedido/:id" component={AsyncOrderStateComponent} />
                           <Route path="/puntos-de-retiro" component={AsyncPuntosDeRetiroComponent} />

                           

                           <Route component={AsyncPageNotFoundComponent} />
                        </Switch>

                        
                        <FooterOne data={footerData} />
                        <SweetAlert
                           success={alertType === 'success'}
                           error={alertType === 'error'}
                           title=''
                           confirmBtnText="Ok"
                           confirmBtnBsStyle="warning"
                           className="iron-alert-box"
                           show={this.props.showAlert}
                           onConfirm={this.props.hideAlert}
                           onCancel={this.props.hideAlert}
                           closeOnClickOutside
                        >
                           {this.props.alertMessage}
                        </SweetAlert>

                     </div>
                  }
                  <ThemeOptions url={this.getUrl(location.pathname)} />
               </Fragment>
               </UserProvider>
            </IntlProvider>
         </MuiThemeProvider>
      )
   }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
   const { showAlert, alertMessage, selectedLocale, alertType,theme,darkMode,rtlLayout } = appSettings;
   return { showAlert, alertMessage, selectedLocale, alertType,theme,darkMode,rtlLayout };
}

export default connect(mapStateToProps, {
   hideAlert
})(App);