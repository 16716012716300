// import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// configureStore 
import { configureStore } from './store/index';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';


// store




export const store = configureStore();



ReactDOM.render(
   <ClearCacheProvider auto={true}>
      <Provider store={store}>
         <BrowserRouter>
            <Switch>
               <Route path="/" component={App} />
            </Switch>
         </BrowserRouter>
      </Provider>
   </ClearCacheProvider>
   , document.getElementById('root'));


   // const configuration = {
   //    onUpdate: (registration) => {
   //      if (registration && registration.waiting) {
   //        if (window.confirm('New version available!  refresh to update your app?')) {
   //          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
   //          window.location.reload();
   //        }
   //      }
   //    }
   //   };

registerServiceWorker();
