import React, { useState, useEffect } from "react";

import axios from "axios";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { Link, withRouter } from "react-router-dom";
import Popover from "@material-ui/core/Popover";

import Store from "@material-ui/icons/Store";

import Button from "@material-ui/core/Button";

//connect to store
import UserContext from "../../../userContext";

function StoreSelectMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  //   state = {
  //     anchorEl: null,
  //     result: null,
  //     isAuthenticated: false,
  //     menus: [
  //       {
  //         id: 1,
  //         title: "Mis datos",
  //         icon: "account_circle",
  //         path: "/account/profile",
  //       },

  //       //{
  //       //    id: 4,
  //       //    title: 'Salir',
  //       //    icon: 'power_settings_new',
  //       //    path: '/sign-in'
  //       //}
  //     ],
  //   };

  //define function for open dropdown
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //define function for close dropdown
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  //   render() {
  //       const { anchorEl } = this.state;
  //       const open = Boolean(anchorEl);

  return (
    <div className="iron-logout-wrap">
      <Avatar
        aria-owns={Boolean(anchorEl) ? "menu-appbar" : null}
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleMenu}
        className="icon-btn mr-10"
        aria-label="Puntos de retiro"
      >
        <i className="material-icons">store</i>
      </Avatar>

      <div>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="iron-dropdown"
        >
          {/* {this.state.menus.map((menu, index) => (
            <MenuItem
              key={index}
              onClick={this.handleClose}
              to={menu.path}
              component={Link}
              title={menu.title}
            >
              <span className="mb-0">{menu.title}</span>
            </MenuItem>
          ))} */}

          <MenuItem
            onClick={handleClose}
            to="/sucursales"
            component={Link}
            title="Ver sucursales"
          >
            <span className="mb-0">Ver sucursales</span>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default StoreSelectMenu;
