/**
 * site header one component
 */
/* eslint-disable */
import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

// components
import LanguageProvider from "./LanguageProvider";
import HeaderMenu from "./HeaderMenu";
import CurrencyProvider from "./CurrencyProvider";
import Cart from "./Cart";
import Wishlist from "./Wishlist";
import Logout from "./Logout";
import StoreSelectMenu from "./StoreSelectMenu";

import SearchBox from "./SearchBox";
import SidebarMenu from "../sidebar";
import FixedHeader from "../headers/FixedHeader";
import AppConfig from "../../../constants/AppConfig";
import { axiosInstance } from "../../../util/axiosInstance";
import ViewCartSlide from "../../../components/widgets/ViewCartSlide";

import LocationOn from "@material-ui/icons/LocationOn";
import { IconButton } from "@material-ui/core";

class HeaderOne extends React.Component {
  state = {
    fixedHeader: false,
    navLinks: [],
  };

  componentDidMount() {
    this.getNavLinksData();
  }

  getNavLinksData() {
    axiosInstance.get("/api/Home/GetHeaderMenu/").then((response) => {
      this.setState({
        navLinks: response.data,
      });
    });
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("scroll", this.hideBar);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideBar);
  }

  // function to show and hide fixed header
  hideBar = () => {
    const { fixedHeader } = this.state;
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.scrollTop >= 200
      ? !fixedHeader && this.setState({ fixedHeader: true })
      : fixedHeader && this.setState({ fixedHeader: false });
  };

  render() {
    return (
      <div>
        <AppBar
          position="static"
          style={{ background: "#FFF" }}
          className={`iron-header-wrapper iron-header-v1 ${
            this.state.fixedHeader ? "header-fixed" : ""
          }`}
        >
          <div className="iron-header-top pt-30">
            <div className="container">
              <Grid container spacing={0}>
                <Grid
                  item
                  md={4}
                  lg={4}
                  xl={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <div className="iron-header-widgets d-flex justify-content-start align-items-center"></div>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <div className="iron-app-logo text-md-center">
                    <Link to="/">
                      <img
                        src={AppConfig.AppLogo}
                        alt={AppConfig.AppLogoAlt}
                        width={AppConfig.AppLogoWidth}
                      />
                    </Link>
                    {/* <Typography variant="title" color="inherit" className="text-uppercase ">  
                                        Embryo
                                    </Typography> */}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <div className="iron-header-widgets d-flex justify-content-end align-items-center ">
                    {/* <Wishlist /> */}
                    {/* <StoreSelectMenu />                              */}

                    <Link to="/puntos-de-retiro">
                      <IconButton
                        variant="contained"
                        color="primary"
                        className="icon-btn mr-10 be-2-ubicacion"
                        aria-label="Puntos de retiro"
                      >
                        <i className="material-icons">location_on</i>
                      </IconButton>
                    </Link>

                    <Logout />

                    <ViewCartSlide />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="iron-header-bottom">
            <div className="container">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="text-center position-relative">
                    <HeaderMenu navLinks={this.state.navLinks} />
                    <SidebarMenu navLinks={this.state.navLinks} />
                    {/* <SearchBox /> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <FixedHeader navLinks={this.state.navLinks} />
        </AppBar>
      </div>
    );
  }
}

export default HeaderOne;
