/**
 * App Constants
*/

export default {
   MercadoPagoPublicKey: 'TEST-e5a852a3-eaec-44d9-bec6-d24411ac733c',
   Header: 1,
   Banner: 3,
   LoadingGif: require('../assets/images/loading.gif'),        
   AppLogo: require('../assets/images/logo.svg'),         // App logo
   HandyLogo: require('../assets/images/handy-logo.svg'),         // App logo
   MercadoPagoLogo: require('../assets/images/mercadopago.png'),   
   AppLogoAlt:'Beautydermus',
   AppLogoWidth:400,
   rtlLayout: false,                                             // RTL Layout
   adminLayout: false,                                           // Admin Layout
	navCollapsed: false,                                          // Sidebar Nav Layout
   wishList: false,
   algoliaConfig: {                                              // Algolia configuration
      appId: 'latency',
      apiKey: '3d9875e51fbd20c7754e65422f7ce5e1',
      indexName: 'bestbuy'
   },
   // Default locale
   locale: {
      locale: 'en',
      name: 'English',
      icon: 'en',
   },   
   AboutUs: 'Este es un texto de pruebas',   
   CopyrightText: '© All Rights Reversed | Developed by www.cretai.com',
   TokenKey: "PARAISOANIMAL_TOKEN"


}