/**
 * View cart sidebar component
 */
/* eslint-disable */
import React, { Fragment } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CurrencyIcon from "../global/currency/CurrencyIcon";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
//connect to store
import { connect } from "react-redux";

//action
import { removeProductItem } from "../../actions/action";

//global component
import ConfirmationDialog from "../global/confirmation-popup";

// helpers
import { getSubTotalPrice, getTotalPrice } from "../../helpers";
import DeleteIcon from "@material-ui/icons/Delete";
import { Chip, Typography } from "@material-ui/core";

import { CardGiftcard } from "@material-ui/icons";


import { withRouter } from "react-router-dom";



class ViewCartSlide extends React.Component {
  constructor(props) {
    super(props);
    this.confirmationDialog = React.createRef();
    this.state = {
      right: false,
    };

    this.finalizarCompraClick = this.finalizarCompraClick.bind(this);

  }
  /**
   * function for toggle sidebar
   */
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  /**
   * function to delete product from cart
   * @param {object} cartItem
   */
  onDeleteCartItem(cartItem) {
    this.props.removeProductItem(cartItem);
    // this.cartItem = cartItem;
    // this.confirmationDialog.current.openDialog();
  }

  /**
   * function for delete cart product
   * @param {boolean} popupResponse
   */
  deleteCartItem(popupResponse) {
    if (popupResponse) {
      this.props.removeProductItem(this.cartItem);
      this.cartItem = "";
    }
    this.setState({
      right: false,
    });
  }

  //get url
  getUrl(url) {
    return url.split("/")[0];
  }

  finalizarCompraClick(){
    this.setState({
      right: false,
    });

    
    this.props.history.push("/payment");
  }

  render() {
    const { cart, tax, shipping } = this.props;
    return (
      <div className="iron-view-cart-wrapper">
        <IconButton
          aria-owns={open ? "simple-popper" : null}
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={this.toggleDrawer("right", true)}
          className="icon-btn mr-10 be-2-ver-carrito"
          aria-label="Cart"
        >
          {cart && cart.length > 0 ? (
            <Badge
              badgeContent={cart.length}
              color="primary"
              className="badge-active"
            >
              <i className="material-icons be-2-ver-carrito">shopping_cart</i>
            </Badge>
          ) : (
            <i className="material-icons be-2-ver-carrito">shopping_cart</i>
          )}
        </IconButton>

        <SwipeableDrawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
          onOpen={this.toggleDrawer("right", true)}
        >
          <div
            tabIndex={0}
            role="button"
            className="iron-overflow-x-hidden"
            //onClick={this.toggleDrawer('right', false)}
            // onKeyDown={this.toggleDrawer('right', false)}
          >
            <div className="iron-view-cart-sidebar">
              <div className="side-cart-head text-center py-10 px-10 bg-primary">
                <h5 className="mb-0">Carrito</h5>
              </div>
              <div className="side-cart-wrapper" style={{paddingRight:'1.5rem'}}>
                {cart && cart.length > 0 ? (
                  <Fragment>
                    <div>
                      {cart.map((cartItem, index) => {
                        return (
                          <Grid
                            key={index}
                            container
                            spacing={2}
                            style={{ borderBottom: "1px solid #eee" }}
                            className="p-10"
                          >
                            <Grid item xs={2}>
                              <center>
                                {this.getUrl(cartItem.image) === "https:" ? (
                                  <img
                                    src={
                                      "data:image/png;base64," + cartItem.image
                                    }
                                    alt="cart-item"
                                  />
                                ) : (
                                  <img
                                    src={
                                      "data:image/png;base64," + cartItem.image
                                    }
                                    alt="cart-item"
                                  />
                                )}
                              </center>
                            </Grid>
                            <Grid item xs={9}>
                              <div className="title">
                                <p
                                  className="mb-5"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  {cartItem.name}
                                </p>
                                <p className="mb-5">
                                  <span>{cartItem.quantity}</span>
                                </p>

                                <p className="mb-5">
                                  <CurrencyIcon />
                                  {cartItem.totalPrice !== null && cartItem.totalPrice.toFixed(2)}
                                </p>

                                {cartItem.isGift && (
                                  <Chip
                                    label="Producto de regalo"
                                    variant="outlined"
                                    style={{
                                      backgroundColor: "#e0729a",
                                      borderColor: "#e0729a",
                                      color: "#fff",
                                    }}
                                    icon={
                                      <CardGiftcard style={{ color: "#fff" }} />
                                    }
                                  />
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => this.onDeleteCartItem(cartItem)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </div>
                    <div className="py-20" style={{padding:'1.5rem'}}>
                      <div className="d-flex justify-content-between align-items-center mb-20">
                        <span>Subtotal</span>
                        <span>
                          <CurrencyIcon /> {getSubTotalPrice()}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-20">
                        <span>Envío</span>
                        <span>
                          <CurrencyIcon /> {shipping}
                        </span>
                      </div>
                      {/*
                                    <div className="d-flex justify-content-between align-items-center mb-20">
                                       <span>Tax(GST)</span><span><CurrencyIcon /> {tax}</span>
                                    </div> */}
                      <Divider className="my-20" />
                      <div className="mb-25 d-flex justify-content-between align-items-center">
                        <h4>Total</h4>
                        <span></span>
                        <h4>
                          {" "}
                          <CurrencyIcon /> {getTotalPrice("VCart")}
                        </h4>
                      </div>
                    </div>
                    <div style={{padding:'1.5rem'}}>
                    <Button
                      component={Link}
                      to="/cart"
                      className="button btn-primary mt-25 btn-sm rounded w-100 be-2-ver-carrito"
                      variant="contained"
                      startIcon={<ShoppingCartIcon />}
                    >
                      Ver carrito
                    </Button>

                    {getTotalPrice("VCart") > 0 && (
                      <Button
                        // component={Link}
                        // to="/payment"
                        variant="contained"
                        className="button btn-primary mt-25 btn-sm rounded w-100"
                        color="primary"
                        onClick={this.finalizarCompraClick}
                        type="button"
                      >
                        Finalizar compra
                      </Button>
                    )}

                    </div>
                    
                    
                  </Fragment>
                ) : (
                  <div className="section-pad text-center">
                    <div className="mb-30">
                      <img
                        src={require("../../assets/images/empty-cart.png")}
                        alt="shop-cart"
                        style={{ width: "100px" }}
                      />
                    </div>
                    <h4>Tu carrito esta vacio.</h4>
                  </div>
                )}
              </div>
              <ConfirmationDialog
                ref={this.confirmationDialog}
                onConfirm={(res) => this.deleteCartItem(res)}
              />
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ ecommerce }) => {
  const { cart, tax, shipping } = ecommerce;
  return { cart, tax, shipping };
};



export default withRouter(
  connect(mapStateToProps, {
    removeProductItem,
  })(ViewCartSlide)
);
