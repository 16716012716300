/**
 * Content loader
 */
/* eslint-disable */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppConfig from '../../../constants/AppConfig';

const ContentLoader = () => (
   <div className="iron-progress-bar d-flex justify-content-center align-items-center">
      <img src={AppConfig.LoadingGif} alt="Espere por favor" width="150" />
   </div>
);

export default ContentLoader;