/**
 * Helpers Method
 */
import { store } from "../index";

/**
 *  function to check product is exits in cart or not
 */
export function isProductExist(ID, colorID, sizeID) {
   let exists = false
   let cart = store.getState().ecommerce.cart;
   if (cart && cart.length > 0) {
      for (const cartItem of cart) {
         if (cartItem.productID === ID && cartItem.colorID === colorID && cartItem.sizeID === sizeID) {
            exists = true
         }
      }
   }
   return exists;
}

export function isProductGiftExist(ID) {
   let exists = false
   let cart = store.getState().ecommerce.cart;
   if (cart && cart.length > 0) {
      for (const cartItem of cart) {
         if (cartItem.productID === ID && cartItem.isGift) {
            exists = true
         }
      }
   }
   return exists;
}

export function getCartLength(){
   return store.getState().ecommerce.cart.length;
}

export function getCartItem(ID, colorID) {

   let cartItemReturn = null;
   let cart = store.getState().ecommerce.cart;
   if (cart && cart.length > 0) {
       for (const cartItem of cart) {

           if (cartItem.productID === ID && cartItem.colorID === colorID) {
               cartItemReturn = cartItem
           }
       }
   }
   return cartItemReturn;
}

/**
 *  function to check product is exits in wishlist or not
 */
export function productExitsInWishlist(ID) {
   let exist = false
   let wishlist = store.getState().ecommerce.wishlist;
   if (wishlist && wishlist.length > 0) {
      for (const item of wishlist) {
         if (item.productID === ID) {
            exist = true
         }
      }
   }
   return exist;
}

/**
 * Get subTotal Price
 */
export function getSubTotalPrice() {
   let subTotalPrice = 0;
   let cart = store.getState().ecommerce.cart;
   if (cart && cart.length > 0) {
      for (const cartItem of cart) {
         subTotalPrice += cartItem.totalPrice;
      }
      return subTotalPrice;
   }
}

/**
 * get Total Price
 */
export function getTotalPrice() {
   const { tax, shipping, couponDiscount } = store.getState().ecommerce;
   const totalPrice = getSubTotalPrice() + shipping + tax;

   

   if(couponDiscount > 0){
      const totalPriceWithDiscount = totalPrice.toFixed(2) * ((100 - couponDiscount) / 100)
      return totalPriceWithDiscount.toFixed(2);
   }
   else{
      return totalPrice.toFixed(2);
   }
}
