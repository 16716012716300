/**
 * Ecommerce Reducer
 */

//action types
import {
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  REMOVE_FROM_CART,
  UPDATE_PRODUCT_QUANTITY,
  FINAL_PAYMENT,
  MOVE_WISHLISTITEM_TO_CART,
  REMOVE_FROM_WISHLIST,
  DELETE_USER,
  ADD_NEW_USER,
  ORDER_CODE,
  SET_COUPON_DISCOUNT,
  UPDATE_SHIPPING_COST,
  CLEAR_CART,
  SET_RETIRE_POINT,
} from "../actions/types";

import { getCart, saveCart } from "../helpers/localStorage";

import { axiosInstance } from "../util/axiosInstance";
//initial data
let cartData = getCart();

let wishlistData = [];
let collaborationData = [];
const TAX = 0;
const SHIPPING = 0;
const COUPON_DISCOUNT = 0;

const INITIAL_STATE = {
  cart: cartData,
  wishlist: wishlistData,
  tax: TAX,
  couponDiscount: COUPON_DISCOUNT,
  receiptProducts: null,
  collaborationData: collaborationData,
  retirePoint: null,
  shipping: SHIPPING,
  retireType: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // add product to cart
    case ADD_TO_CART:
      let product = action.payload;

      var unitPriceToPay = 0;

      if (product.priceWithDiscount > 0) {
        unitPriceToPay = product.priceWithDiscount;
      } else {
        unitPriceToPay = product.price;
      }

      let newProductData = {
        productID: product.objectID,
        image: product.image,
        name: product.name,
        quantity: product.quantity ? product.quantity : 1,
        price: product.price,
        priceWithDiscount: product.priceWithDiscount,
        priceToPay:
          product.priceWithDiscount > 0
            ? product.priceWithDiscount
            : product.price,
        totalPrice: unitPriceToPay * product.quantity,
        colorID: product.colorID,
        colorName: product.colorName,
        sizeID: product.sizeID,
        sizeDescription: product.sizeDescription,
        index: product.index,
        isGift: product.isGift,
        minimumAmount: product.minimumAmount,
        haveDiscount: product.haveDiscount,
      };

      var returnState = {
        ...state,
        cart: [...state.cart, newProductData],
        totalPrice: state.totalPrice + newProductData.price,
      };

      saveCart(returnState.cart);

      return returnState;

    // add product to wishlist
    case ADD_TO_WISHLIST:
      let wishlistItem = action.payload;
      let newWishlistItem = {
        productID: wishlistItem.objectID,
        image: wishlistItem.image,
        name: wishlistItem.name,
        quantity: 1,
        price: wishlistItem.price,
        totalPrice: wishlistItem.price,
      };
      return {
        ...state,
        wishlist: [...state.wishlist, newWishlistItem],
      };
    // move wishlist product to cart
    case MOVE_WISHLISTITEM_TO_CART:
      let data = state.wishlist;
      for (const wishlistItem of data) {
        let newItem = {
          productID: wishlistItem.objectID,
          image: wishlistItem.image,
          name: wishlistItem.name,
          quantity: 1,
          price: wishlistItem.price,
          totalPrice: wishlistItem.totalPrice,
        };
        state.cart.push(newItem);
      }
      return {
        ...state,
        cart: [...state.cart],
        wishlist: [],
      };
    // delete product from wishlist
    case REMOVE_FROM_WISHLIST:
      let deleteItem = action.payload;
      let wishlist = state.wishlist.filter(
        (wishlistItem) => wishlistItem.productID !== deleteItem.productID
      );
      return {
        ...state,
        wishlist,
      };
    // update product item quantity
    case UPDATE_PRODUCT_QUANTITY:
      let newData = action.payload.cartItem;

      let newCartData = [];

      // for (const cartItem of state.cart) {
      //   if (
      //     cartItem.productID === newData.productID &&
      //     cartItem.colorID === newData.colorID
      //   ) {
      //     cartItem.quantity = action.payload.newQuantity;
      //     cartItem.totalPrice = cartItem.priceToPay * cartItem.quantity;
      //   }
      //   newCartData.push(cartItem);
      // }

      for (const cartItem of state.cart) {


        var unitPriceToPay = 0;

        if (cartItem.priceWithDiscount > 0) {
          unitPriceToPay = cartItem.priceWithDiscount;
        } else {
          unitPriceToPay = cartItem.price;
        }


        if (cartItem.productID === newData.productID) {
          var totalPrice = 0;
          const newQuantity = action.payload.newQuantity;

          if (cartItem.haveDiscount) {

            if (cartItem.minimumAmount > 1) {



              const minimumAmount = cartItem.minimumAmount;
              console.log("minimumAmount: ", minimumAmount);
              
              
              const remainder = newQuantity % minimumAmount;
              
              
              
              
              totalPrice = cartItem.priceWithDiscount * (newQuantity - remainder);
              
              
              //el producto q sobra se paga entero
              if (remainder > 0) {
                console.log("remainder: ", remainder);
                totalPrice += cartItem.price * remainder;
              }





            } else {
              //descuento normal

              totalPrice = unitPriceToPay * newQuantity;
            }
          } else {
            totalPrice = unitPriceToPay * newQuantity;
          }

          cartItem.quantity = newQuantity;
          cartItem.totalPrice = totalPrice;
        }
        newCartData.push(cartItem);
      }

      var returnState = {
        ...state,
        cart: newCartData,
        totalPrice: state.totalPrice,
      };

      saveCart(returnState.cart);

      return returnState;

    // remove product to cart
    case REMOVE_FROM_CART:
      let removeItem = action.payload;

      let cart = state.cart.filter(
        (cartItem) => cartItem.index !== removeItem.index
      );

      var i = 0;
      for (const cartItem of cart) {
        cartItem.index = i;
        i++;
      }

      if (cart.length == 1) {
        if (cart[0].isGift) {
          cart = [];
        }
      }

      var returnState = {
        ...state,
        cart,
        totalPrice: state.totalPrice - removeItem.price,
      };

      saveCart(returnState.cart);

      return returnState;

    // final statement (invoice)
    case FINAL_PAYMENT:
      let checkOutProducts = state.cart;
      return {
        ...state,
        receiptProducts: checkOutProducts,
        cart: [],
      };
    // delete user (admin-panel)
    case DELETE_USER:
      let removeUser = action.payload;
      let NewUserList = state.collaborationData.filter(
        (listItem) => listItem.id !== removeUser.id
      );
      return {
        ...state,
        collaborationData: NewUserList,
      };
    // add product to cart
    case ADD_NEW_USER:
      let newUser = action.payload;
      let newUserInfo = {
        name: newUser.name,
        email: newUser.email,
        access: newUser.access,
        image: "user-edit.png",
      };
      return {
        ...state,
        collaborationData: [...state.collaborationData, newUserInfo],
      };

    case ORDER_CODE:
      let orderCode = action.payload;
      return {
        ...state,
        orderCode: orderCode,
      };

    case SET_COUPON_DISCOUNT:
      let discount = action.payload;
      return {
        ...state,
        couponDiscount: discount,
      };

    case UPDATE_SHIPPING_COST:
      let shippingData = action.payload;

      return {
        ...state,
        shipping: shippingData.cost,
        retireType: shippingData.retireType,
      };

    case CLEAR_CART:
      var returnState = {
        ...state,
        cart: [],
      };

      saveCart(returnState.cart);

      return returnState;

    case SET_RETIRE_POINT:
      let retirePoint = action.payload;
      return {
        ...state,
        retirePoint: retirePoint,
      };
    // default case
    default:
      return { ...state };
  }
};
