/**
 * site header two component
 */
/* eslint-disable */
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';


import { axiosInstance } from '../../../util/axiosInstance';


// components
import HeaderMenu from "./HeaderMenu";
import Cart from "./Cart";
import Wishlist from "./Wishlist";
import Logout from "./Logout";
import FixedHeaderBlur from '../headers/FixedHeaderBlur';
import SidebarMenu from '../sidebar';
import AppConfig from '../../../constants/AppConfig';
import SearchBox from './SearchBox';
import SearchBoxV2 from './SearchBoxV2';
import ViewCartSlide from '../../../components/widgets/ViewCartSlide';



class HeaderTwo extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
          fixedHeader: false,
          navLinks: [],
      }
   }   
 
   componentDidMount() {
      this.getNavLinksData();
   }

   UNSAFE_componentWillMount() {
      window.addEventListener('scroll', this.hideBar);
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.hideBar);
   }

   getNavLinksData() {
      axiosInstance.get('/api/Home/GetHeaderMenu/').then(
          response => {
              this.setState({
                  navLinks: response.data,
              });
          });
  }
  

   //Function to show and hide fixed header
   hideBar = () => {
      const { fixedHeader } = this.state;
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.scrollTop >= 200 ?
         !fixedHeader && this.setState({ fixedHeader: true })
         :
         fixedHeader && this.setState({ fixedHeader: false });
   }

   render() {

      const wishList = AppConfig.wishList;

      return (
         <div>
            <div position="static" className={` iron-header-v2 ${(this.state.fixedHeader) ? 'header-fixed' : ''}`}>
               
               <div className="iron-header-top py-5 bg-base">
                  <div className="container rounded top-0 shadow border" >
                     <Grid container spacing={2} >
                        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} className="d-flex justify-content-start align-items-center" >
                           <div className="iron-app-logo">

                              { /* 
                              <img src={AppConfig.AppLogo} alt={AppConfig.AppLogoAlt} width={AppConfig.AppLogoWidth} />
                              */ }
                              <Link to="/">
                                 <h1 className="beauty-logo">Beautydermus</h1>
                              </Link>



                           </div>
                        </Grid>
                        <Grid item xs={3} sm={6} md={6}  lg={6} xl={6} justify="center" className=" d-flex align-items-center" >
                           <div className="text-center">
                              <HeaderMenu navLinks={this.state.navLinks} />
                           </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="d-flex justify-content-end align-items-center" >
                           <div className="iron-header-widgets d-flex justify-content-end align-items-center ">
                              <ViewCartSlide />                              
                              {wishList ?  <Wishlist /> : null}
                              <Logout />
                              <SidebarMenu navLinks={this.state.navLinks} />

                           </div>
                        </Grid>
                     </Grid>
                  </div>
               </div>
               <div className="iron-header-bottom bg-base">
                  <div className="container">
                     <Grid container spacing={0} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                           <div className="position-relative">
                       


                              {/* <SearchBox /> */}
                           </div>
                        </Grid>
                     </Grid>
                  </div>
               </div>
               
               <FixedHeaderBlur navLinks={this.state.navLinks}/>
            </div>
         </div>
      );
   }
}

export default HeaderTwo;