/**
 * Paraiso animal theme
 */
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
   palette: {
		type: "light",
      primary: {
         main: '#e9d6af',
         contrastText: '#3E3F41',

      },
      secondary: {
         main: '#8c1637',
         contrastText: '#fff',
      }
	}
	,overrides: {
		MuiTableRow:{
			root: {
				height:48
			},
		},
	},
	typography: {

        button: {
            fontFamily: "Futura Md BT",
            textTransform: "none",
        },
        label: {
            fontFamily: "Futura Md BT"
		}
    },
});

export default theme;