import Axios from "axios";

import AppConfig from "../constants/AppConfig"
import { axiosInstance } from "./axiosInstance"

export function setToken(token) {
    localStorage.setItem(AppConfig.TokenKey, token);
}

export async function getToken() {
    return localStorage.getItem(AppConfig.TokenKey);
}

export function deleteToken() {
    localStorage.removeItem(AppConfig.TokenKey);
}

export async function getCurrentUser() {
    
    if (!getToken()) return false;

    try {
        let response = await axiosInstance.get("/api/v1/auth/current");
        return response.data;
    } 
    catch (error) 
    {
        return false;
    }
}


