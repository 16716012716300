/**
 * Fixed header component
 */
/* eslint-disable */
import React from 'react';
import Grid from "@material-ui/core/Grid";

// components
import HeaderMenu from "./HeaderMenu";
import SidebarMenu from '../sidebar';
import AppConfig from '../../../constants/AppConfig';


function FixedHeader(props) {
   const navLinks = props.navLinks;

   return (
      <div className="iron-fixed-header bg-base">
         <div className="container">
            <Grid container spacing={0} >
               <Grid item xs={5} sm={5} md={2} lg={2} xl={2} className="d-flex justify-content-start align-items-center" >
                  <div className="iron-app-logo py-sm-10 py-20">
                     <img src={AppConfig.AppLogo} alt={AppConfig.AppLogoAlt} width={AppConfig.AppLogoWidth} /> 
                     <h1 className="beauty-logo-white">Beautydermus</h1>
                  </div>
               </Grid>
               <Grid item xs={6} sm={6} md={9} lg={9} xl={9} >
                  <div className="text-right">
                     <HeaderMenu  navLinks={navLinks} showCart={true} />
                     <SidebarMenu navLinks={navLinks}/>

                  </div>
               </Grid>
               
            </Grid>
         </div>
      </div>
   );
}

export default FixedHeader;