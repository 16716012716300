/**
 * site footer one component
 */
/* eslint-disable */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import Wave from 'react-wavify'
// intl message
import IntlMessages from '../../../util/IntlMessages';

//App Config
import AppConfig from '../../../constants/AppConfig';

import { FaInstagram, FaFacebookF   } from 'react-icons/fa';


export default function FooterOne(props) {

   const { social, session, categories, about } = props.data;
   return (

     

      <div>
      {/* <Wave fill='#f8f9fa'
        paused={true}
        options={{
          height: 20,
          amplitude: 60,
          speed: 0.15,
          points: 3
        }}
  /> */}
      
      <img width="100%" src={require('../../../assets/images/footer.jpg')} alt='footer' />
      <footer className="iron-footer-v1">
         <div className="iron-footer-top ">
            <div className="container" >
               <Grid container spacing={3} >
                  <Grid item xs={12} md={3} lg={3} xl={3} >
                     <div>
                        <center>
                        <div className="footer-widget-title mb-30">

                           <h6>Redes sociales</h6>
                        </div>
                        <ul className="iron-footer-menu-list" style={{listStyle:'none'}}>
                              <li className="list-item">
                                 <a href="https://www.instagram.com/beautydermus_uy/" target="_blank" rel="noopener">
                                 <FaInstagram style={{fontSize:'40px'}} /> Beautydermus_uy
                                 </a>
                              </li>

                              <li className="list-item">
                                 <a href="https://www.facebook.com/profile.php?id=100076433524918" target="_blank" rel="noopener">
                                 <FaFacebookF style={{fontSize:'40px'}} /> Beautydermus
                                 </a>
                              </li>

                           </ul>
                           </center>

                     </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                     <div>
                        <div className="footer-widget-title mb-30">
                           <h6>Beautydermus</h6>
                        </div>
                        <List component="nav" className="iron-footer-menu-list">
                           {
                              about.map((aboutdata, key) => {
                                 return (
                                    <li key={key} className="list-item">
                                       <Link to={aboutdata.path} >
                                          <IntlMessages id={aboutdata.menu_title} />
                                       </Link>
                                    </li>
                                 )
                              })
                           }
                        </List>
                     </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                     <div>
                        <div className="footer-widget-title mb-30">
                           <h6>Sesión</h6>
                        </div>
                        <List component="nav" className="iron-footer-menu-list">
                           {
                              session.map((session, key) => {
                                 return (
                                    <li key={key} className="list-item">
                                       <Link to={session.path}>
                                          <IntlMessages id={session.menu_title} />
                                       </Link>
                                    </li>
                                 )
                              })
                           }
                        </List>
                     </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                     <center>
                        <div className="footer-widget-title mb-30">
                           <h6> Líneas</h6>
                        </div>
                        <List component="nav" className="iron-footer-menu-list">
                           {
                              categories.map((category, key) => {
                                 return (
                                    <li key={key} className="list-item">
                                       <Link to={category.path}>
                                          <IntlMessages id={category.menu_title} />
                                       </Link>
                                    </li>
                                 )
                              })
                           }
                        </List>
                     </center>
                  </Grid>
                 
               </Grid>

            </div>
         </div>
         <div className="iron-footer-bottom">
            <div className="container">
               <Grid container >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="text-center text-lg-left mb-30 mb-lg-0">
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                     <div className="iron-copyright text-lg-right text-center">
                        <p className="mb-0">{AppConfig.CopyrightText}</p>
                     </div>
                  </Grid>
               </Grid>
            </div>
         </div>
      </footer>
      </div>
   )
}
